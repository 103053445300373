<template>
    <frontoffice-layout>
        <div class="shell">
            <div class="min-h-screen--with-header flex justify-between">
                <div class="w-full flex flex-col justify-center sm:max-w-80 sm:items-start py-8 <sm:justify-end">
                    <header class="mb-3">
                        <v-heading level="h1" class="!text-10xl"> {{ $t('404.title') }} </v-heading>
                        <v-heading level="h2" class="!text-4xl whitespace-pre-line"> {{ $t('404.subtitle') }} </v-heading>
                    </header>

                    <p class="whitespace-pre-line"> {{ $t('404.entry') }} </p>

                    <footer class="mt-4">
                        <v-button variant="primary" :to="{ name: 'home' }"> {{ $t('404.back') }} </v-button>
                    </footer>
                </div>

                <div class="flex items-start justify-end <sm:absolute <sm:-z-1 <sm:max-w-80 <sm:opacity-80 <sm:-right-12">
                    <img :src="require('@/assets/images/traffic-light.png')" alt="404" class="block -mt-8" />
                </div>
            </div>
        </div>
    </frontoffice-layout>
</template>

<script>
export default {

}
</script>
